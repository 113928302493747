import { render, staticRenderFns } from "./venueList.vue?vue&type=template&id=070e3d98&scoped=true"
import script from "./venueList.vue?vue&type=script&lang=js"
export * from "./venueList.vue?vue&type=script&lang=js"
import style0 from "./venueList.vue?vue&type=style&index=0&id=070e3d98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070e3d98",
  null
  
)

export default component.exports